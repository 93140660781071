import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import pic27 from '../assets/images/p27.jpg'


class CardTaller extends React.Component {
  render() {
    const { data } = this.props
    const { edges: taller } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">



        <section id="one" className="tiles">
 
        <article className='taller bg-img' >
        
        </article>
           
            <article className='taller bg-pattern-green'  >
            <header className="major">
           <h1>TALLER EMOCIONES Y CREATIVIDAD</h1>
                </header>
            <div class="grid-wrapper">
    
                <div className='box col-6'> 


               <h4>
                 EMOCIONES, UN INICIO PARA NUESTRO AUTOCONOCIMIENTO
               </h4>

<ul>
  <li>¿Qué son las emociones?-</li>
  <li>¿Por qué trabajar las emociones dentro de nuestros procesos creativos?</li>
  <li>¿Cómo nos acercamos a nuestras emociones?</li>
</ul>

</div>

<div className='box col-6'>
<h4>INTELIGENCIA EMOCIONAL</h4>
<ul>
<li>¿A qué se llama inteligencia emocional?</li>
<li>Inteligencia Emocional/Inteligencia Racional</li>
<li>Mecanismo de la Emociones</li>
<li> Autoconocimiento</li>
<li>Aprendizaje Emocional</li>
</ul>
</div>


<div className='box col-6'>
<h4>
 CREATIVIDAD
</h4>
<ul>
<li>¿A qué llamamos creatividad?</li>
  <li>Elementos claves del proceso creativo
</li>
  <li>La creatividad en nuestra vida Cotidiana
</li>
<li>Adaptación activa/ Salud Mental
</li>
</ul>
</div>

<div className='box col-6'>
<h4>
HERRAMIENTAS PARA POTENCIAR NUESTRA CREATIVIDAD
</h4>
<ul>
<li>Imaginación</li>
  <li>Juego</li>
  <li>Arte</li>
</ul>
</div>


<div className='box col-6'>
<h4>
  ¿QUÉ
  TRABAJAREMOS?!
</h4>
<ul>
<li>Autoconocimiento,
  haciendo uso de los
  sentidos y la imaginación</li>
  <li>Habilidades y actitudes
  personales para la
  creatividad</li>
  <li>Entender la importancia de
  los entornos, cómo los
  diseñamos de manera que
  generen bienestar
  emocional?</li>
</ul>
</div>

<div className='box col-6'>
<h4>
  ¿CÓMO LO
  TRABAJAREMOS?
</h4>
<ul>
<li>Tendremos 4 encuentros
  de 1 hora y media, con
  ejercicios vivenciales e
  intercambio teórico.</li>
  <li>Contaremos con
  ejercicios de
  autoconocimiento entre
  cada encuentro y
  materiales que permitan
  profundizar las temáticas.</li>
  <li>Lo más importante!, se
  realizará en un ambiente
  cuidado, de escucha y de
  intercambio respetuoso.</li>
</ul>
</div>

</div>
           </article>
           <article className='taller bg-img'  >
        

 
           </article>
             
         
      </section></div>
   

    )
  }
}

CardTaller.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query TallerQuery {
        allMarkdownRemark(limit: 1) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CardTaller data={data}  />}
  />
)
