import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import CardTaller from '../components/CardTaller'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
  
  // Demo styles, see 'Styles' section below for some notes on use.
  import 'react-accessible-accordion/dist/fancy-example.css';

const Talleres = (props) => (
    <Layout>
        <Helmet>
            <title>RevelARTE - Talleres</title>
            <meta name="description" content="Landing Page" />
        </Helmet>


        <BannerLanding title='Talleres' class='center-header' subtitle='Creación a través del arte y el juego'/>

      
        <div id="main">
 
    
                <section>


<Accordion allowZeroExpanded='true'>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    EMOCIONES Y CREATIVIDAD
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                   <CardTaller />
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    LOS SENTIDOS Y LA CREATIVIDAD 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>

                    <div className='box'> <h5>Próximamente!</h5></div>
               
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>


                   
                </section>
              
              
        </div>

    </Layout>
)

export default Talleres